import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Last Day of Test Week!`}</em></p>
    <p>{`2 Attempts, Max Unbroken Double Unders`}</p>
    <p>{`then,`}</p>
    <p>{`Full Clean & Jerk 1-1-1-1-1 to a 1RM`}</p>
    <p>{`*`}{`Also find your Full Clean 1RM`}</p>
    <p>{`then,`}</p>
    <p>{`20-Full Clean & Split Jerks (135/95) for time.`}</p>
    <p>{`*`}{`Bonus WOD`}{`*`}</p>
    <p>{`12:00 EMOM of:`}</p>
    <p>{`Odd Minutes: 10 Burpees`}</p>
    <p>{`Even Minutes: 20 KBS’s (53/35)`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`17.4 is released at 8:00pm tonight!  Check out the live
announcement at Games.CrossFit.com.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      